import { Environment } from '@slovgen-ui/shared'
class EnvironmentImpl implements Environment {
    admin = false
    production = true
    version = '1.32.0-5a30b94b'
    defaultBirdSamplingMethod = 9

    sentryDSN = 'https://c2a51a902e644d798c2d56ec15536988@o418115.ingest.sentry.io/5320212'
}

export const environment = new EnvironmentImpl()
