import { HttpClient } from '@angular/common/http'
import { Component, OnInit, signal } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FileDescriptor, OrderDetailItem, OrdersAPIService } from '@slovgen-ui/api'
import { canShowToCustomer } from '@slovgen-ui/order-components'
import { MessageService } from '@slovgen-ui/shared'
import { finalize } from 'rxjs/operators'

@Component({
    selector: 'slovgen-ui-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent implements OnInit {
    id: number
    loading: boolean
    order: OrderDetailItem
    itemResultVisible: { [key: number]: boolean } = {}
    pdfBundleLoading: boolean
    loadingError = signal(null)

    constructor(
        private route: ActivatedRoute,
        private api: OrdersAPIService,
        private httpClient: HttpClient,
        private msgService: MessageService
    ) {}

    downloadFile(itemId: number, protocolId: string) {
        this.httpClient
            .get(`/api/protocols/${this.id}/${itemId}/pdf?record=true`, { responseType: 'blob', observe: 'response' })
            .subscribe((resp) => {
                const blob = new Blob([resp.body], { type: 'application/pdf' })
                const url = window.URL.createObjectURL(blob)
                const link = document.createElement('a')
                link.href = url
                let fileName = `protocol-${protocolId || itemId}.pdf`
                const dispo = resp.headers.get('Content-Disposition')
                if (dispo && dispo.indexOf('filename=') !== -1) {
                    fileName = dispo.split('filename=')[1]
                }
                link.download = fileName
                link.click()
            })
    }
    downloadCertFile(fileRef: FileDescriptor) {
        this.httpClient.get(`/api/files/${fileRef.id}`, { responseType: 'blob' }).subscribe((resp) => {
            const blob = new Blob([resp], { type: fileRef.mediaType })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = fileRef.name || `cert-${this.order.id}-${fileRef.id}.pdf`
            link.click()
        })
    }

    downloadPdfBundle() {
        this.pdfBundleLoading = true
        this.httpClient
            .get(`/api/orders/${this.order.id}/zipBundle`, { responseType: 'blob' })
            .pipe(finalize(() => (this.pdfBundleLoading = false)))
            .subscribe({
                next: (resp) => {
                    if (resp && resp.size > 0) {
                        const blob = new Blob([resp], { type: 'application/zip' })
                        const url = window.URL.createObjectURL(blob)
                        const link = document.createElement('a')
                        link.href = url
                        link.download = `${this.order.id}.zip`
                        link.click()
                        this.msgService.addSuccessMessage('order.downloadPdfBundleOk')
                    }
                },
                error: (err) => {
                    this.msgService.addErrorMessage('order.downloadPdfBundleNok')
                },
            })
    }

    ngOnInit() {
        this.id = +this.route.snapshot.paramMap.get('id')
        this.api
            .findOrderById({ id: this.id })
            .pipe(finalize(() => (this.loading = false)))
            .subscribe({
                next: (resp) => {
                    console.log(`Order loaded: ${resp}`)
                    this.order = resp
                    this.order.items.forEach((i) => (this.itemResultVisible[i.id] = canShowToCustomer(this.order, i)))
                    this.order.items.sort((a, b) => {
                        return a.sample.id - b.sample.id || a.analysisId - b.analysisId
                    })
                },
                error: (err) => {
                    this.loadingError.set(err)
                    console.log(`Failed to load order: ${err}`, err)
                    this.msgService.addErrorMessage('common.loadingError')
                },
            })
    }
}
